import PostList from '~/components/PostList';
import Title from '~/components/Title';

export default function Posts() {
  return (
    <>
      <Title>Todos os artigos</Title>
      <PostList />
    </>
  );
}
